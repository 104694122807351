.transaction-history {
  color: #fff;
  padding-bottom: 2rem;
  &__filter {
    &-block {
    }
    &-item {
      padding: 1rem 1rem 0.5rem 1rem;
    }
    &-title {
      color: #fff;
      margin-bottom: 0.5rem;
    }
  }

  &__input {
    border: 1px solid #fff;
    height: 2.2857rem;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__calendar {
    position: absolute;
    top: calc(505px - 0.85rem);
    right: 0.5rem;
    width: 1.7143rem;
    height: 1.7143rem;
    background: #424242;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1rem;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      background-color: transparent;
      width: 100%;
      height: 2.2857rem;
      outline: none;
      border: none;
      padding: 0;
      color: #fff;
      padding-left: 0.5rem;
      box-sizing: border-box;
    }
  }

  &__search-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #e65c21;
    height: 2.7143rem;
    width: 94%;
    margin: 0.5rem auto;
  }

  &__search-button-icon {
    position: absolute;
    top: calc(50% - 0.78rem);
    right: 0.5rem;
    width: 1.5714rem;
    height: 1.5714rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    &-svg {
      width: 1.1429rem;
      height: 1.1429rem;
      * {
        fill: #181c23;
      }
    }
  }

  &__search-block {
    padding: 0.7rem;
    position: relative;
  }

  &__search-icon {
    position: absolute;
    left: 1.2rem;
    top: calc(50% - 0.5rem);

    &-svg {
      width: 1rem;
      * {
        fill: #fff;
      }
    }
  }

  &__result-wrapper {
    width: 100%;
    padding: 0 3px;
    display: grid;

    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &__user-header-item {
    font-weight: 400;
    font-size: 0.75rem;
    height: 2.5rem;
    color: #fff;
    background: #963b15;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &_left {
      align-items: flex-start;
    }

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  // .transaction-item:nth-child(2n) {
  //   background: #181C23;
  // }
}
