.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__item {
    width: 100%;
    height: 2.8rem;
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    color: #fff;
  }
  &__image {
    width: 1.2rem;
    * {
      fill: #fff;
    }
  }
  &__title {
    background: #963B15;
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
}
