.create-user {
  color: #fff;
  &__header {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 1px;
    height: 2.4286rem;
    background: #963B15;
    font-weight: 700;
    font-size: 0.875rem;
    padding-left: 0.8rem;
  }
  &__wrapper {
    background: #181c23;
    padding: 1rem;
    box-sizing: border-box;
  }
  &__title {
    margin-bottom: 0.4rem;

    &-checkbox {
      margin-left: 1rem;
    }
  }
  &__item {
    margin-bottom: 0.8rem;

    &-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
    }
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.125rem;
    width: 100%;
    background: #E65C21;
    color: #fff;
    position: relative;
    margin: 0 auto;
  }
  &__user-icon-wrapper {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.85rem);
    background: rgba(0, 0, 0, 0.3);
    height: 1.7rem;
    border-radius: 100%;
    width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__user-icon {
    &-svg {
      height: 1.2rem;
    }
  }
}
